import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import parse from 'html-react-parser';
import Slide from 'react-reveal/Slide';
import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Heading from '../../common/components/Heading';
import RImage from '../../common/components/ResponsiveImage';
import Container from '../../common/components/UI/Container';

import {
  TimelineWrapper,
  TimelineItem,
  TimelineIndex,
  TimelineContent,
  TimelineDot,
  Hidden,
} from './working.index';

const Working = ({
  sectionWrapper,
  sectionHeader,
  sectionTitle,
  sectionSubTitle,
  row,
  col,
  textArea,
  imageArea,
  imageTwo,
  indexStyle,
  timelineTitle,
  timelineDescription,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      dataJson {
        COMPUNETWORK {
          title
          description
          imageSection {
            childImageSharp{
              fluid{
                ...GatsbyImageSharpFluid
              }
            }
          }
          imageAlt
          steps {
            title
            description
            buttonText
            buttonPath
          }
        }
      }
    }
  `);

  const { title,
    description,
    imageSection,
    imageAlt,
    steps } = Data.dataJson.COMPUNETWORK 


  return (
    <Box {...sectionWrapper}>
      <Container>
        <Box {...row}>
          <Box {...col} {...imageArea} className="sectionBreakPoint">
            <Fade bottom>
              <RImage fluid={imageSection.childImageSharp.fluid} alt={imageAlt} {...imageTwo} />
            </Fade>
          </Box>
          <Box {...col} {...textArea}>
            
            <TimelineContent>
              <Hidden>
                <Slide bottom delay={100}>
                  <Heading as="h1" content='¡Estamos trabajando!' {...sectionTitle} />
                </Slide>
              </Hidden>
              
              <Hidden>
                <Slide bottom delay={200}>
                  <Text content='Para que pronto puedas acceder de forma rápida y segura a tu información desde cualquier dispositivo.' {...sectionSubTitle}  />
                </Slide>
              </Hidden>
            </TimelineContent>
                  
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

Working.propTypes = {
  sectionWrapper: PropTypes.object,
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  button: PropTypes.object,
  textArea: PropTypes.object,
  imageArea: PropTypes.object,
  imageTwo: PropTypes.object,
  buttonWrapper: PropTypes.object,
  indexStyle: PropTypes.object,
  timelineTitle: PropTypes.object,
  timelineDescription: PropTypes.object,
  timelineShowMore: PropTypes.object,
};

Working.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: ['30px', '40px', '40px', '40px'],
    pb: ['30px', '40px', '40px', '40px'],
  }, // section header default style
  sectionHeader: {
    mb: '56px',
    ml: '10%',
    mr: '10%'
  },
  // section title default style
  sectionTitle: {
    textAlign: 'left',
    paddingBottom: '20px',
    fontSize: ['40px', '50px', '50px', '70px', '70px'],
    fontWeight: 'bold',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  // sub section default style
  sectionSubTitle: {
    textAlign: 'left',
    fontSize: ['16px', '20px', '20px', '25px', '25px'],
    color: 'textColor',
    lineHeight: '1.45',
    mb: '0',

  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  imageAreaRow: {
    flexDirection: 'row-reverse',
  },
  col: {
    pr: '15px',
    pl: '15px',
  },
  textArea: {
    width: ['100%', '100%', '55%', '50%', '55%'],
    mb: ['40px', '40px', '0', '0', '0'],
  },
  imageArea: {
    width: ['100%', '100%', '45%', '50%', '45%'],
    mb: ['30px', '40px', '40px', '0', '0'],
  },
  title: {
    fontSize: ['50px', '60px', '70px', '90px', '90px'],
    fontWeight: '300',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: '15px',
  },
  description: {
    fontSize: '16px',
    color: 'textColor',
    lineHeight: '1.75',
    mb: '33px',
  },
  buttonWrapper: {
    mt: ['25px', '50px'],
    flexBox: true,
    justifyContent: 'center',
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
    height: '46px',
  },
  imageOne: {
    mb: '40px',
    ml: 'auto',
    mr: 'auto',
  },
  imageTwo: {
    ml: 'auto',
    mr: 'auto',
  },
  indexStyle: {
    fontSize: ['50px', '60px', '70px', '90px', '90px'],
    fontWeight: '300',
    color: '#eaebec',
    display: 'block',
    lineHeight: '1',
    mb: '0',
  },
  timelineTitle: {
    fontSize: ['16px', '17px', '18px', '18px', '20px'],
    fontWeight: 'bold',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '13px',
  },
  timelineDescription: {
    fontSize: ['14px', '15px', '15px', '15px', '16px'],
    lineHeight: '1.6',
    color: '#5d646d',
    mb: '1rem'
  },
  timelineShowMore: {
    marginTop: '15px',
    colors: 'primaryWithBg',
    cursor: 'pointer',
  }
};

export default Working;
