import React from "react"
import SEO from "../components/seo"
import Working from "../containers/Working"

const compunetwork = () => (
  <>
    <SEO /> 
    <Working />
  </>
)

export default compunetwork
